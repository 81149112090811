.box {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 10px;
    justify-content: space-between;
}
.box-left, .box-right {
    width: 45%;
    border: 1px solid #cec5c5;
    border-radius: 6px;
    padding: 12px;
}

.list-data, .list-data-seleted {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 275px;
    overflow: auto;
}

.list-data > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-data > li > div {
    width: 50%;
    text-align: center;
    padding: 8px 0px 8px 20px;
}

/* .list-data > li */
.head {
    display: flex;
    justify-content: space-around;
    border: 1px solid lightgray;
    padding: 8px 0px 8px 20px;
    background-color: #cecece;
}

/* .list-data > li > div:nth-child(1) {
    border-right: 1px solid #cecece;
} */

.list-data-seleted > li.head {
    justify-content: space-around;
    border: 1px solid lightgray;
    padding: 8px 0px 8px 20px;
}

.list-data-seleted > li {    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-data > li:nth-child(odd),
.list-data-seleted > li:nth-child(odd) {
    background-color: #efeeee;
}

.list-data > li:nth-child(even),
.list-data-seleted > li:nth-child(even) {
    background-color: #f6f6f6;
}

.area-data-selected {
    display: flex;
    align-items: center;
    width: 75%;
    justify-content: space-around;
}

.selected-name {
    margin: 0 30px;
}

.icon-delete {
    cursor: pointer;
    margin-right: 28px;
}