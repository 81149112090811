/* .table-container-group {
    display: block;
    height: 450px;
    overflow-y: scroll;
} */

.table-container-group thead {
    background-color: #7F7F7F;
    color: #ffff
}

.table-container-group thead tr td {
    padding: 8px;
}

.table-container-group tbody tr td {
    padding: 0 14px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}